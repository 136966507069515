import React, { useState } from "react";
import NumberedContentCol from "./Col";

export const NumberedContentRow = (props) => {
  const [bgImage, setBgImage] = useState("none");
  const [active, setActive] = useState("");

  const renderCols = (row) => {
    return row.map((element, index) => {
      return (
        <NumberedContentCol
          key={index}
          number={element.number}
          heading={element.heading}
          text={element.text}
          image={element.image}
          changeImage={setBgImage}
          setActive={setActive}
        />
      );
    });
  };

  return (
    <>
      <div className={`row numbered-content__row ${active}`}>
        <div className={`col-xl-4 mb-5 numbered-content__col ${props.order}`}>
          <div
            className="numbered-content__gif"
            style={{ backgroundImage: `url(${bgImage})` }}
          ></div>
        </div>
        {renderCols(props.row)}
      </div>
    </>
  );
};

export default NumberedContentRow;
