import React from "react";
import NumberedContentRow from "./Row";

export const NumberedContentContainer = () => {
  const row1 = [
    {
      number: "01",
      heading: "Be Seen",
      text:
        "Reach more people with a brochure that is in everyone's pocket. Word of mouth is the most powerful form of advertising. Take this to the next level and let your customers share your website with their friends.",
      image: "/images/wave.gif",
    },
    {
      number: "02",
      heading: "Time is precious",
      text:
        "Not only your customers time, but yours too. Don't get stuck on the phone answering the same questions again and again. Put that information on your website and keep everyone happy.",
      image: "/images/time.gif",
    },
  ];

  const row2 = [
    {
      number: "03",
      heading: "Keep local customers",
      text:
        "No longer are you only competing with businesses in your region, now you have competition everywhere. Many of these competitors will already have websites promoting their point of difference. Level the playing field with your own website.",
      image: "/images/baby-cheering.gif",
    },
    {
      number: "04",
      heading: "Better advertising",
      text:
        "Advertising online gives you precise reporting for all of your marketing. Not only will you know it’s working, but you can also target specific audiences based  location and interests.",
      image: "/images/target.gif",
    },
  ];

  const row3 = [
    {
      number: "05",
      heading: "Answer the basic questions",
      text:
        "When are you open? Can I book online? How do I get in touch with you? If a customer can’t find these answers they will go elsewhere.",
      image: "/images/cram-knowledge.gif",
    },
    {
      number: "06",
      heading: "Show your work",
      text:
        "Show the quality of your work and build trust with your customers. Prove yourself online and start getting the types of customers and jobs that you want.",

      image: "/images/wow.gif",
    },
  ];

  return (
    <>
      <NumberedContentRow order="order-first" row={row1} />
      <NumberedContentRow order="order-last" row={row2} />
      <NumberedContentRow order="order-first" row={row3} />
    </>
  );
};

export default NumberedContentContainer;
