import * as React from "react";
import Layout from "../components/Layout";
import NumberedContentContainer from "../components/NumberedContent/Container";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const WhyHaveAWebsite = () => {
  return (
    <Layout>
      <Helmet>
        <title>Why you should have a website | Sixvees</title>
        <meta
          name="description"
          content="Sixvees will create you a website that can be found easily when searched, looks professional, enhances your sales, and has a point of difference."
        ></meta>
      </Helmet>
      <main>
        <div className="page-header sr-only">
          <h1>Why have a website?</h1>
        </div>

        <section className="primary-content">
          <div className="primary-content__content">
            <div className="container">
              <h2>Are you on the fence about creating a website?</h2>
              <p>
                The first thing a potential customer will do is Google you!
                That’s why it’s so important to have a high-quality website.
              </p>
              <p>
                A website is a 24/7 representation of your business and is only
                1 click away at any given moment.
              </p>
              <p>You need one! A good one! And we can help.</p>
              <p>
                Sixvees will create you a website that can be found easily when
                searched, looks professional, enhances your sales, and has a
                point of difference.
              </p>
            </div>
          </div>
          <div className="icon-scroll" style={{ opacity: 1 }} />
        </section>

        <section className="numbered-content dark-strip-top pt-0">
          <div className="container">
            <h2 className="mb-5 text-white-stroke heading-sm ">
              What can a website do for you?
            </h2>
            <NumberedContentContainer />
          </div>
        </section>

        <section
          className="myths pt-0 white-strip-top"
          style={{
            background:
              "url(/images/ponding-thoughts-yosemite.jpg) no-repeat 50%/cover",
          }}
        >
          <div className="container">
            <h2
              className="text-dark heading-sm text-right ml-auto mb-5 aos-init aos-animate"
              data-aos="fade-left"
              data-aos-anchor-placement="top-center"
            >
              <span className="text-dark-stroke text-white">
                You need a website,
              </span>{" "}
              a good one
            </h2>
            <div className="row pt-4">
              <div className="col-lg-6 ml-auto">
                <h3 className="text-dark-stroke text-right">
                  "Can’t I just make my own?"
                </h3>
                <p>
                  You can, for sure! There are plenty of DIY tools available.
                  However, just because you have a pair of scissors, doesn’t
                  mean you should cut your own hair. A lot more goes into making
                  a successful website than meets the eye. Save money and
                  achieve better results with the help of professionals.
                </p>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-6 ml-auto">
                <h3 className="text-dark-stroke text-right">
                  "I don't need any more customers"
                </h3>
                <p>
                  A website can be more than just a sales tool. Existing
                  customers want to know the quick details – who were they
                  speaking to, and what time are you closing? Imagine the time
                  you can save on the phone when customers can find the answers
                  on your website.
                </p>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-lg-6 ml-auto">
                <h3 className="text-dark-stroke text-right">
                  "Our customers aren’t computer users"
                </h3>
                <p>
                  Computer users aren’t just people using desktop computers. It
                  also includes people browsing websites, social media, and apps
                  on mobile devices. If your customers use phones, they are
                  computer users.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="cta bg-dark">
          <div className="container">
            <div className="cta__content mt-n4">
              <h4 className="mb-0 mt-4">What are you waiting for? </h4>
              <Link
                to="/how-to-get-online/"
                className="btn btn-outline-light mt-4"
              >
                Get started!
              </Link>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default WhyHaveAWebsite;
