import React from "react";

export const NumberedContentCol = (props) => {
  const handleEnter = () => {
    props.changeImage(props.image);
    props.setActive("active");
  };

  const handleLeave = () => {
    props.changeImage("none");
    props.setActive("");
  };
  return (
    <div
      className={`col-sm-6 col-xl-4 mb-5`}
      onMouseEnter={() => handleEnter()}
      onMouseLeave={() => handleLeave()}
      role="none"
    >
      <div className={`numbered-content__content`}>
        <span className="numbered-content__number">{props.number}</span>
        <h5 className="numbered-content__heading">{props.heading}</h5>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default NumberedContentCol;
